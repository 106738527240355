<template>
    <div v-if="progress !== 'hide'" class="rentea-progress-timeline-holder">
        <div
            id="rentea-navtab-1"
            class="rentea-timeline-tab timeline-tab-lgreen"
            :class="{ nofill: progress <= 0 }"
        ></div>
        <div
            id="rentea-navtab-2"
            class="rentea-timeline-tab timeline-tab-lgreen"
            :class="{ nofill: progress <= 1 }"
        ></div>
        <div
            id="rentea-navtab-3"
            class="rentea-timeline-tab timeline-tab-lgreen"
            :class="{ nofill: progress <= 2 }"
        ></div>
        <div
            v-if="!skipTimeline"
            id="rentea-navtab-4"
            class="rentea-timeline-tab timeline-tab-lgreen"
            :class="{ nofill: progress <= 3 }"
        ></div>
        <div
            v-if="!skipTimeline"
            id="rentea-navtab-5"
            class="rentea-timeline-tab timeline-tab-lgreen"
            :class="{ nofill: progress <= 4 }"
        ></div>
        <div
            v-if="!skipTimeline"
            id="rentea-navtab-6"
            class="rentea-timeline-tab timeline-tab-lgreen"
            :class="{ nofill: progress <= 5 }"
        ></div>
        <div
            v-if="!skipTimeline"
            id="rentea-navtab-7"
            class="rentea-timeline-tab timeline-tab-lgreen"
            :class="{ nofill: progress <= 6 }"
        ></div>
        <div
            id="rentea-navtab-8"
            class="rentea-timeline-tab timeline-tab-lgreen"
            :class="{ nofill: progress <= 7 }"
        ></div>
        <div
            id="rentea-navtab-9"
            class="rentea-timeline-tab timeline-tab-lgreen"
            :class="{ nofill: progress <= 8 }"
        ></div>
        <div
            id="rentea-navtab-10"
            class="rentea-timeline-tab timeline-tab-lgreen"
            :class="{ nofill: progress <= 9 }"
        ></div>
        <div
            id="rentea-navtab-11"
            class="rentea-timeline-tab timeline-tab-lgreen"
            :class="{ nofill: progress <= 10 }"
        ></div>
        <div
            id="rentea-navtab-12"
            class="rentea-timeline-tab timeline-tab-dgreen"
            :class="{ nofill: progress <= 11 }"
        ></div>
        <div
            id="rentea-navtab-13"
            class="rentea-timeline-tab timeline-tab-dgreen"
            :class="{ nofill: progress <= 12 }"
        ></div>
        <div
            id="rentea-navtab-14"
            class="rentea-timeline-tab timeline-tab-dgreen"
            :class="{ nofill: progress <= 13 }"
        ></div>
        <div
            id="rentea-navtab-14"
            class="rentea-timeline-tab timeline-tab-dgreen"
            :class="{ nofill: progress <= 14 }"
        ></div>
        <div
            id="rentea-navtab-14"
            class="rentea-timeline-tab timeline-tab-dgreen"
            :class="{ nofill: progress <= 15 }"
        ></div>
        <div
            id="rentea-navtab-15"
            class="rentea-timeline-tab timeline-tab-blue"
            :class="{ nofill: progress <= 16 }"
        ></div>
        <div
            id="rentea-navtab-16"
            class="rentea-timeline-tab timeline-tab-blue"
            :class="{ nofill: progress <= 17 }"
        ></div>
        <div
            id="rentea-navtab-17"
            class="rentea-timeline-tab timeline-tab-blue"
            :class="{ nofill: progress <= 18 }"
        ></div>
        <!--        <div-->
        <!--            id="rentea-navtab-18"-->
        <!--            class="rentea-timeline-tab timeline-tab-blue"-->
        <!--            :class="{ nofill: progress <= 19 }"-->
        <!--        ></div>-->
        <!--        <div-->
        <!--            id="rentea-navtab-19"-->
        <!--            class="rentea-timeline-tab timeline-tab-blue"-->
        <!--            :class="{ nofill: progress <= 20 }"-->
        <!--        ></div>-->
    </div>
</template>

<script setup>
import { useStore } from "vuex";
import { computed } from "vue";

const store = useStore();

//Pokud bude skipped timeline, od všech čísel odečteme 6 (nebo kolik idc)
const progress = computed(() => store.getters.progressTimelineValues);
const skipTimeline = computed(() => store.getters.skipTimeline);
</script>

<style scoped>
.rentea-progress-timeline-holder {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.rentea-timeline-tab {
    height: 20px;
    width: 100%;
    margin-right: 10px;
    border-bottom: #000 solid 2px;
}

.rentea-timeline-tab:last-child {
    margin-right: 0;
}

.timeline-tab-lgreen {
    border-color: #bcd543;
    background: #bcd543;
}

.timeline-tab-dgreen {
    border-color: #529546;
    background: #529546;
}

.timeline-tab-blue {
    border-color: #0b369c;
    background: #0b369c;
}

/*TODO Přidat param, kterej bude přímo na divu a bude se starat o rozhazování barev tak jak má být*/
.nofill {
    background: #e1e1f1;
}
</style>
