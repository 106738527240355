<template>
    <svg
        width="121"
        height="64"
        viewBox="0 0 121 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M58.4255 63.9997H121L94.6293 18.4856C87.9961 7.03767 75.7847 -0.0075531 62.5749 -0.0075531H0L26.3707 45.5065C33.0039 56.9544 45.2156 63.9997 58.4255 63.9997Z"
            fill="#BCD543"
        />
    </svg>
</template>
