import { createRouter, createWebHistory } from "vue-router";

const routes = [
    {
        path: "/advisor",
        name: "advisor",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/AdvisorView.vue"),
    },
    {
        path: "/",
        name: "steps",
        component: () => import("../views/StepsView"),
    },
    // {
    //     path: "/",
    //     name: "advisor",
    //     component: () => import("../views/AdvisorView"),
    // },
    {
        path: "/dotaznik/otazka11",
        name: "questionnaire0",
        component: () => import("../views/Questionnaires/QuestionZeroView.vue"),
    },
    {
        path: "/dotaznik/otazka1",
        name: "questionnaire1",
        component: () => import("../views/Questionnaires/QuestionOneView.vue"),
    },
    {
        path: "/dotaznik/otazka2",
        name: "questionnaire2",
        component: () => import("../views/Questionnaires/QuestionTwoView.vue"),
    },
    {
        path: "/dotaznik/otazka3",
        name: "questionnaire3",
        component: () =>
            import("../views/Questionnaires/QuestionThreeView.vue"),
    },
    {
        path: "/dotaznik/otazka4",
        name: "questionnaire4",
        component: () => import("../views/Questionnaires/QuestionFourView.vue"),
    },
    {
        path: "/dotaznik/otazka5",
        name: "questionnaire5",
        component: () => import("../views/Questionnaires/QuestionFiveView.vue"),
    },
    {
        path: "/dotaznik/otazka6",
        name: "questionnaire6",
        component: () => import("../views/Questionnaires/QuestionSixView.vue"),
    },
    {
        path: "/dotaznik/otazka7",
        name: "questionnaire7",
        component: () =>
            import("../views/Questionnaires/QuestionSevenView.vue"),
    },
    {
        path: "/dotaznik/otazka8",
        name: "questionnaire8",
        component: () =>
            import("../views/Questionnaires/QuestionEightView.vue"),
    },
    {
        path: "/dotaznik/otazka9",
        name: "questionnaire9",
        component: () => import("../views/Questionnaires/QuestionNineView.vue"),
    },
    {
        path: "/dotaznik/otazka10",
        name: "questionnaire10",
        component: () => import("../views/Questionnaires/QuestionTenView.vue"),
    },
    {
        path: "/strategie",
        name: "strategy",
        component: () => import("../views/StrategyView.vue"),
    },
    {
        path: "/detail",
        name: "person",
        component: () => import("../views/PersonView.vue"),
    },
    {
        path: "/prevod",
        name: "transfer",
        component: () => import("../views/TransferView.vue"),
    },
    {
        path: "/datum-pocatku",
        name: "startDate",
        component: () => import("../views/DateStartView.vue"),
    },
    {
        path: "/identifikace",
        name: "identification",
        component: () => import("../views/IdentificationView.vue"),
    },
    {
        path: "/souhrn",
        name: "summary",
        component: () => import("../views/SummaryView.vue"),
    },
    {
        path: "/podpis",
        name: "signature",
        component: () => import("../views/SignatureView.vue"),
    },
    {
        path: "/hotovo",
        name: "feedback",
        component: () => import("../views/FeedbackView.vue"),
    },
    {
        path: "/error",
        name: "error",
        component: () => import("../views/ErrorView.vue"),
    },
    {
        path: "/finalPage",
        name: "finalPage",
        component: () => import("../views/FinalView.vue"),
    },
    {
        path: "/respo",
        name: "respo",
        component: () => import("../views/RespoView.vue"),
    },
    {
        path: "/bankid",
        name: "redirect",
        component: () => import("../views/RedirectView.vue"),
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
