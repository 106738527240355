import { createI18n } from "vue-i18n";

const messages = {
    cs: {
        global: {
            common: {
                birthDate: "Datum narození",
            },
            buttons: {
                next: "Pokračovat",
                select: "Vybrat",
                cancel: "Zrušit",
                next_question: "Další otázka",
            },
            errors: {
                question_not_answered_button:
                    "Nejdříve prosím zodpovězte otázku.",
                questions_not_answered_button:
                    "Nejsou vyplněna všechna potřebná pole otázky.",
            },
        },
        stepsPage: {
            title: "Co budete potřebovat?",
            step1: "Bankovní identitu od banky podporující podpis balíčku dokumentů. ",
            step2: "Rodné číslo přidělené v České republice",
            step3: "7 minut vašeho času",
            step4: "Pokud už máte spoření sjednáno, pak informace ke stávajícímu spoření u jiné penzijní společnosti",
            availabilityHint:
                "Smluvní dokumenty se v procesu podepisují bezpečnou metodou s využitím podepisovací služby společnosti Bankovní identita a.s. Bohužel, ne všechny banky, které nabízejí službu ověření identity, podporují zároveň i službu podpisu balíčku dokumentů. Můžete se obrátit na svou banku s dotazem, kdy bude tuto službu podporovat.",
            alert: "Smlouvu je možné sjednat online pouze s identifikací prostřednitvím Bank iD.",
        },
        questionnaires: {
            title: "Jak rozumíte investování?",
            subtitle:
                "Zákon nám ukládá povinnost zjistit, jaké znalosti a zkušenosti máte v oblasti financí a investičních nástrojů. Používáme k tomu následující " +
                "dotazník. Po vyplnění Vám dokážeme navrhnout vhodnou strategii spoření. Můžete si zvolit i vlastní.",
            question0: {
                title: "Abychom Vám mohli doporučit pro Vás nejvýhodnější strategii, potřebujeme znát věk účastníka smlouvy. Tedy osoby, pro kterou se smlouva sjednává (pokud smlouvu sjednáváte pro dítě, uveďte datum narození dítěte).",
            },
            question1: {
                title: "Jste zaměstnán nebo byl jste zaměstnán v oboru souvisejícím s investicemi?",
                answers: {
                    answer1:
                        "Pracuji nebo jsem dříve více než rok pracoval/a ve finančním sektoru, ale nezastávám pozici přímo spojenou s obchodováním s investičními nástroji.",
                    answer2:
                        "Ano, zastávám nebo jsem dříve více než rok zastával/a pozici přímo spojenou s obchodováním s investičními nástroji (např. makléře, portfolio manažera, investičního poradce apod.).",
                    answer3: "Ne.",
                },
            },
            question2: {
                title: "Máte vzdělání či kvalifikaci vztahující se k obchodování s investičními nástroji?",
                answers: {
                    answer1:
                        "Ano - vysokoškolské vzdělání se zaměřením mj. na finanční trhy a investiční nástroje nebo odbornou zkoušku nebo jsem prošel odborným kurzem se zaměřením na obchodování s investičními nástroji (makléřská zkouška, odborné vzdělání pro registraci investičního zprostředkovatele/vázaného zástupce apod.).",
                    answer2:
                        "Ne, ale znalosti a zkušenosti jsem získal/a při investování vlastních peněžních prostředků nebo samostudiem.",
                    answer3: "Nemám investiční vzdělání ani zkušenosti.",
                },
            },
            question3: {
                title: "Jaké jsou hlavní výhody investování do otevřených podílových fondů kolektivního investování?",
                answers: {
                    answer1:
                        "Diverzifikace, likvidita a aktivní správa spojená s regulací, snižující rizika investice.",
                    answer2: "Garance výnosu z investovaných prostředků.",
                    answer3: "Garance návratnosti investovaných prostředků.",
                    answer4:
                        "Možnost okamžitého zpětného získání investovaných prostředků.",
                },
            },
            question4: {
                title: "Každá investice je spojena s určitým stupněm rizika. Na základě Vašich znalostí označte pravdivé tvrzení.",
                answers: {
                    answer1:
                        "Rizikovost investice nijak nesouvisí s potenciálním výnosem.",
                    answer2: "Nižší výnos je vždy spojen s vyšším rizikem.",
                    answer3:
                        "Potenciální vyšší výnos investice je obvykle spojen s vyšším rizikem.",
                    answer4:
                        "Investice do dluhopisu s výnosem 4 % p.a. je bezriziková.",
                },
            },
            question5: {
                title: "Které z níže uvedených portfolií nese menší riziko? Portfolio složené:",
                answers: {
                    answer1:
                        "z investice do akcií technologických společností.",
                    answer2: "z více podílových fondů.",
                    answer3: "z několika akcií.",
                    answer4:
                        "z 5 letého dluhopisu nově založené společnosti s výnosem 9 % p.a. v kombinaci s akciemi největších světových farmaceutických společností.",
                },
            },
            question6: {
                title: "Zákonná garance návratnosti investovaných prostředků je obsažena v:",
                answers: {
                    answer1:
                        "akciovém otevřeném podílovém fondu investujícím do akcií největších amerických společností.",
                    answer2:
                        "dluhopisovém otevřeném podílovém fondu investujícím pouze do státních německých dluhopisů.",
                    answer3:
                        "investici do 5letého korporátního dluhopisu s výnosem 6% p.a.",
                    answer4: "žádné z výše uvedených variant.",
                },
            },
            question7: {
                title: "Uveďte Vaše zkušenosti s investičními nástroji (vyplňte alespoň jednu odpověď v každém řádku).",
                titlePhone:
                    "Uveďte Vaše zkušenosti s investičními nástroji (vyplňte alespoň jednu odpověď v každé sekci).",
                text: {
                    i_invested: "Investoval(a) jsem:",
                },
                investments: {
                    short_term: "Fondy krátkodobých investic",
                    bonds: "Fondy dluhopisové",
                    mixed_properties: "Fondy smíšené a nemovitostní",
                    stocks: "Fondy akciové, komoditní a alternativní",
                },
                values: {
                    never: "Nikdy",
                    last_3_years: "V posledních 3 letech",
                    regular_basis: "Na pravidelné bázi (měsíc, kvartál)",
                    more_than_3_years: "Držím investice již více než 3 roky",
                    more_than_100_k: "Celkem více než 100.000 CZK",
                },
            },
            question8: {
                title: "Se kterou z níže uvedených možností se ztotožňujete nejvíce?",
                answers: {
                    answer1:
                        "Investice beru jako zhodnocení svých volných finančních prostředků a jsem ochoten akceptovat dočasný vyšší pokles hodnoty investovaných prostředků.",
                    answer2:
                        "Prioritou je ochrana investovaných prostředků před inflací, účelem není primárně reálné zhodnocení prostředků (nad inflaci) a jsem ochoten riskovat pouze nízký pokles hodnoty investovaných prostředků.",
                    answer3: "Nejsem ochoten riskovat žádné ztráty.",
                },
            },
            question9: {
                title: "Jaká z níže uvedených variant vývoje ceny investičního nástroje (pokles, nárůst) je nejbližší Vašim očekáváním?",
                answers: {
                    answer1: "Fond Krátkodobých investic.",
                    answer2: "Fond Dluhopisový.",
                    answer3: "Fond Smíšený.",
                    answer4: "Fond Akciový.",
                },
            },
            question10: {
                title: "Pokud by cena investičního nástroje v rámci vývoje poklesla více než ve Vámi zvolené variantě v předchozí otázce (tedy potenciální ztráta by byla vyšší než očekávaná), jak byste se zachoval/a?",
                answers: {
                    answer1:
                        "Abych neztratil/a ještě víc, investici zruším a vyberu.",
                    answer2:
                        "Do investice nebudu nijak zasahovat, dodržím investiční horizont.",
                    answer3:
                        "Přikoupím podobnou investici, protože nakupuji levně.",
                },
            },
        },
    },
};

export default createI18n({
    locale: "cs",
    legacy: false,
    enableInSFC: false,
    messages,
});
